import Image from "next/image"
import map from "../../../../public/assets/green-map.webp"
import imgL from "../../../../public/assets/users/new-m10.jpg"
import imgR from "../../../../public/assets/users/new.jpg"


export const WorldMapNew = () => {

    return(
        <div className="relative w-full ">
            <div className="bg-contain overflow-hidden pt-[1.2rem] left-[50%] translate-x-[-50%] h-screen w-[105%] absolute mobile:h-[39rem] ">
                <div className="scaling-bg
                bg-white-100
                rounded-[2rem]
                w-full
                h-full
                mobile:mt-[3rem]
                ">
                </div>

                <div className="svgs-container w-full absolute top-0 mask-container">
                    <div className="left-map absolute h-screen w-[calc(100vw/12*4)] pt-[3.9rem] pb-[2rem] mobile:h-[62vh] mobile:w-[9rem]">
                        <Image  alt="" src={map} placeholder="blur" className="h-full object-right svgMapL object-cover" />
                    </div>
                    <div className="right-map absolute right-0 h-screen w-[calc(100vw/12*4)] pt-[3.92rem] pb-[1.95rem] mobile:h-[62vh] mobile:w-[9rem]">
                        <Image   alt="" src={map} placeholder="blur" className="h-full object-left  svgMapR object-cover"/>
                    </div>
                </div>

                <div className="white-vignette">
                </div>

                <div className="mask-container tablet:hidden">
                    <div className="left-pin w-[40vw] h-screen absolute top-0">
                        <div className="w-[5.31rem] absolute h-[5.31rem] top-[33.2%] left-[35.2%] rounded-full p-[.16rem] bg-white-100 shadow-sm">
                            <Image  alt="" width={2000} height={2000} className="rounded-[100%] w-full h-full object-cover object-top" src={imgL}/>
                        </div>
                        <div className="left-[45.3%] top-[calc(33.3%+5.31rem)] blue-pin"></div>
                    </div>
                    <div className="right-pin w-[40vw] h-screen absolute top-0 right-0">
                        <div className="w-[5.31rem] absolute h-[5.31rem] top-[33.2%] right-[35.2%] rounded-full p-[.16rem] bg-white-100 shadow-sm">
                            <Image  alt="" width={2000} height={2000} className="rounded-[100%]  w-full h-full object-cover" src={imgR}/>
                        </div>
                        <div className="right-[45.3%] top-[calc(33.3%+5.31rem)] blue-pin"></div>
                    </div>
                </div>
            </div>
            <div className="relative tilt-text text-black w-[23rem] text-center flex flex-col justify-center mx-auto min-h-screen tablet:w-[12rem] tablet:pt-[6rem] mobile:w-[12rem] mobile:pt-0 mobile:min-h-[39rem] mobile:pt-[6rem] gap-[.3rem]">
                <h2 className="text-[2.40rem] font-medium capitalize leading-[1.25] pb-[1rem] mobile:text-[3rem] mobile:pb-[1.6rem]">Buenro</h2>
                {/* <p className="text-regular30 pb-[.3rem] mobile:text-[1.2em]">Your people, and flexible living, together in stunning homes around the world.<span className="mobile:block"> Dreamy, right?</span> </p> */}
            <div className="w-full tablet:mt-[12%] mobile:mt-0">

            
            <p className="handwritten-text text-[1.76rem] mobile:text-[2.3rem]">
            Means “good vibes“ in Spanish
            </p>

            </div>
            </div>

        </div>
    )
}