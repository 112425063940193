import Image from "next/image";
import { StaticImageData } from "next/image";

interface MemberCardProps {
  src: StaticImageData;
  name: string;
  title: string;
  front: Boolean;
  flag: StaticImageData;
}

export const MemberCard: React.FC<MemberCardProps> = ({
  src,
  title,
  name,
  front,
  flag,
}) => {
  return (

    <div className={`shrink-0 will-change-transform ${front===true ? "z-10" : "sides"}`}>
            <Image src={src} placeholder="blur" sizes="(max-width: 1800px) 384px" loading="lazy" alt="" className={`w-[8.1rem] h-[8.1rem] mx-auto rounded-full mb-[.87rem] object-cover  ${front===true? "border-[.4rem] border-white-100 w-[8.9rem] h-[8.9rem] mt-[-.5rem] shadow-member mobile:w-[9.8rem] mobile:h-[9.8rem] mobile:mt-[-.9rem]": "mobile:w-[8rem] mobile:h-[8rem]"}`}/>

            <p className={`text-regular30 ${front? "":"mobile:mt-[1.8rem] "}mobile:text-[.9rem]`}>{name} <Image className="w-[.7rem] inline-block mobile:w-[.9rem]" src={flag} alt=""/></p>
            <p className="text-[.556rem] text-gray-500 leading-[1.4] mobile:text-[.7rem]">
                      {title}
      </p>
    </div>
  );
};
