"use client"
import { useRef, MouseEvent } from "react";
import gsap from "gsap";
import { WorldMapNew } from './WorldMap';

export const MouseMove = () => {
 const gsapRef = useRef<HTMLDivElement>(null);

 const moveAnimation = (e: MouseEvent<HTMLDivElement>) => {
   if (gsapRef.current) {
     const valX = (e.clientX / gsapRef.current.getBoundingClientRect().width - .5);
     const valY = ((e.clientY / gsapRef.current.getBoundingClientRect().height - .5));

     gsap.context(() => {
      //  gsap.set(".mapSvg", {scale:2})
       gsap.to([".svgMapL", ".svgMapR"], {
         x: (i, target) => -valX * 40,
         y: (i, target) => -valY * 35,
         duration: .8,
         ease: "power1.out"
       });
       gsap.to([".left-pin", ".right-pin"], {
         x: (i, target) => -valX * 60,
         y: (i, target) => -valY * 50,
         duration: .8,
         ease: "power1.out"
       });
       gsap.to([".tilt-text h2", ".tilt-text svg"], {
         rotateY: -valX * 9,
         duration: 1.4,
         ease: "power1.out"
       });
     }, gsapRef.current);
   }

//    console.log(e.screenX);
 }

 return (

   <section className='relative w-[100%] z-[10] overflow-hidden mobile:mt-[-2rem]' ref={gsapRef}>

     <WorldMapNew />
     <div className="mouse-move-contain w-full h-screen absolute top-0 mobile:h-[39rem]" onMouseMove={moveAnimation}></div>
   </section>
 );
}
