"use client"
import {useEffect, useRef,useContext, useState} from "react"
import  {ThemeContext}  from "../providers/index";
import gsap from "gsap"

export const Loader = ()=>{
  const el = useRef<HTMLDivElement>(null)
  const {changeLoading,changeInitLoad,initLoad}= useContext(ThemeContext);
  // const {showLoading,setShowLoading} = useState(true)
  useEffect(()=>{
    if(el.current && initLoad){
    let ctx = gsap.context(() => {
      // gsap.set(".logo-svg", {xPercent:-50})
      const buenroType = ".bue-text"
      const tl = gsap.timeline({});
      tl.to(buenroType,{
        yPercent:(i)=> i===0? -55: 55,
        duration:.8,
        ease:"expo.out",
      },"1")
      .to(buenroType,{
        xPercent:(i)=> i===0? 39:-39,
        duration:.75,
        ease:"power1.in",
      },"<.2")
      .fromTo(".mask",{
        xPercent:(i)=> i===0? 100:-100,
      },{
        xPercent:(i)=> i===0? 0:0,
        display:"Block",
        duration:.8,
        ease:"power1.in",
      },"<")
      .to(".mask",{
        xPercent:(i)=> i===0? -50:50,
        // width:0,
        duration:.36,
        // ease:"power1.out",
        ease:"none",
      },">")
      .to(".type-container",{
        autoAlpha:0,
        duration:0,
      },"<")
      .to(".mask",{
        autoAlpha:(i)=> i===0? 1:0,
        duration:0,
      })
      .to(".mask",{
        scaleX: 0,
        ease:"expo.out",
        transformOrigin:"center",
        duration:1.1,
      },"<")
      .to(".buenro-logo",{
        scale:.4,
        opacity:0,
        ease:"power2.out",
        transformOrigin:"center",
        duration:.6,
        onStart:()=> changeLoading(true),
      },"<-=.2")
      .to(el.current,{
        autoAlpha:0,
        duration:0,
        onComplete:()=> changeInitLoad(false)
      })
       return ()=> ctx.revert()
     }, el.current);
    }

  },[])

  return(
    <div ref={el} className={`loader-container h-screen fixed w-full z-[999] ${!initLoad && "hidden"}`}>
      <div className=" type-container bg-black flex gap-[.45rem] w-full h-full items-center justify-center">
      
        <svg className="bue-text h-[2.2rem] w-auto" xmlns="http://www.w3.org/2000/svg" width="224" height="87" viewBox="0 0 224 87" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M46.5002 42.2216C51.1669 43.0544 55.1253 45.5111 58.3753 49.5917C61.6253 53.6723 63.2503 58.2942 63.2503 63.4574C63.2503 67.8711 62.0836 71.8684 59.7503 75.4493C57.5003 78.947 54.2086 81.7368 49.8753 83.8187C45.5419 85.8174 40.5002 86.8167 34.7502 86.8167H0V0H33.1252C39.0419 0 44.1252 0.999331 48.3752 2.99799C52.6253 4.99665 55.8336 7.70317 58.0003 11.1175C60.167 14.4486 61.2503 18.1961 61.2503 22.36C61.2503 27.3566 59.917 31.5205 57.2503 34.8516C54.5836 38.1827 51.0003 40.6394 46.5002 42.2216ZM14.2501 36.4755H31.8752C36.5418 36.4755 40.1669 35.4345 42.7502 33.3526C45.4169 31.1874 46.7502 28.1061 46.7502 24.1088C46.7502 20.1948 45.4169 17.1551 42.7502 14.9899C40.1669 12.7414 36.5418 11.6172 31.8752 11.6172H14.2501V36.4755ZM33.5002 75.1995C38.3335 75.1995 42.1252 74.0336 44.8752 71.7019C47.6252 69.3701 49.0002 66.1223 49.0002 61.9584C49.0002 57.7112 47.5419 54.3385 44.6252 51.8402C41.7085 49.3419 37.8335 48.0927 33.0002 48.0927H14.2501V75.1995H33.5002Z" fill="white"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M223.038 49.6963C223.038 52.2779 222.872 54.6097 222.538 56.6916H169.913C170.33 62.188 172.371 66.6017 176.038 69.9328C179.705 73.2638 184.205 74.9294 189.538 74.9294C197.205 74.9294 202.621 71.7232 205.788 65.3109H221.163C219.08 71.6399 215.288 76.8448 209.788 80.9254C204.371 84.9227 197.621 86.9213 189.538 86.9213C182.955 86.9213 177.038 85.464 171.788 82.5493C166.621 79.5513 162.538 75.3874 159.538 70.0577C156.621 64.6446 155.163 58.3988 155.163 51.3202C155.163 44.2417 156.58 38.0375 159.413 32.7077C162.33 27.2947 166.371 23.1308 171.538 20.2161C176.788 17.3014 182.788 15.8441 189.538 15.8441C196.038 15.8441 201.83 17.2598 206.913 20.0912C211.996 22.9227 215.955 26.92 218.788 32.0832C221.622 37.1631 223.038 43.0341 223.038 49.6963ZM208.163 45.1994C208.08 39.9529 206.205 35.7474 202.538 32.5828C198.871 29.4183 194.33 27.836 188.913 27.836C183.996 27.836 179.788 29.4183 176.288 32.5828C172.788 35.6641 170.705 39.8696 170.038 45.1994H208.163Z" fill="white"/>
          <path d="M91.7474 83.4414C95.9967 85.6912 102.363 86.8347 109.37 86.8161C116.281 86.7977 120.675 86.7518 126.742 83.4414C131.757 80.7049 134.756 78.3533 137.676 73.4425C140.99 67.869 140.99 57.4442 140.99 57.4442V15.8038L126.742 15.8038L126.742 55.3194C126.742 61.4854 125.2 66.1933 122.118 69.4429C118.951 72.7759 114.702 74.4424 109.37 74.4424C104.037 74.4424 99.8294 72.7759 96.7466 69.4429C93.6638 66.1932 92.1223 61.4854 92.1223 55.3194V15.8038L77.9996 15.8038L77.9996 57.4442C77.9996 63.6935 79.2077 69.0263 81.624 73.4425C84.0403 77.8587 87.4148 81.1917 91.7474 83.4414Z" fill="white"/>
        </svg>

        <svg className="bue-text w-auto h-[2.2rem] pt-[.4rem]" xmlns="http://www.w3.org/2000/svg" width="195" height="72" viewBox="0 0 195 72" fill="none">
          <path d="M31.6199 0.88206C38.6269 0.863445 44.9928 2.00694 49.2421 4.25669C53.5748 6.50646 56.9492 9.83944 59.3655 14.2556C61.7818 18.6718 62.9899 24.0046 62.9899 30.254V71.8943H48.8672V32.3787C48.8672 26.2127 47.3258 21.5049 44.2429 18.2552C41.1601 14.9222 36.9524 13.2557 31.6199 13.2557C26.2875 13.2557 22.0381 14.9222 18.872 18.2552C15.7891 21.5049 14.2477 26.2127 14.2477 32.3787V71.8943H0V30.254C0 30.254 2.38419e-06 19.8291 3.31371 14.2556C6.23343 9.34487 9.23258 6.99325 14.2477 4.25669C20.3144 0.946345 24.7089 0.900418 31.6199 0.88206Z" fill="white"/>
          <path d="M95.4115 12.0059C97.4945 8.50625 100.244 5.79821 103.66 3.88174C107.16 1.88195 111.284 0.88206 116.033 0.88206V15.6305H112.409C106.826 15.6305 102.577 17.047 99.6608 19.8801C96.8279 22.7131 95.4115 27.6292 95.4115 34.6285V71.8943H81.1638V0.98733H95.4115V12.0059Z" fill="white"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M141.378 67.625C146.627 70.5414 152.501 71.9996 159 71.9996C165.582 71.9996 171.581 70.5414 176.997 67.625C182.496 64.6253 186.829 60.4174 189.995 55.0013C193.161 49.5852 194.744 43.3775 194.744 36.3783C194.744 29.379 193.203 23.1714 190.12 17.7553C187.037 12.3392 182.829 8.17295 177.497 5.25659C172.165 2.34024 166.207 0.88206 159.625 0.88206C153.043 0.88206 147.085 2.34024 141.753 5.25659C136.42 8.17295 132.213 12.3392 129.13 17.7553C126.047 23.1714 124.505 29.379 124.505 36.3783C124.505 43.4608 126.005 49.7102 129.005 55.1263C132.004 60.459 136.129 64.6253 141.378 67.625ZM169.248 57.0011C166.082 58.7509 162.666 59.6258 159 59.6258C153.251 59.6258 148.46 57.626 144.627 53.6265C140.878 49.5436 139.003 43.7941 139.003 36.3783C139.003 31.3788 139.92 27.1709 141.753 23.7546C143.586 20.255 146.044 17.6303 149.127 15.8805C152.293 14.1306 155.709 13.2557 159.375 13.2557C163.041 13.2557 166.457 14.1306 169.623 15.8805C172.789 17.6303 175.331 20.255 177.247 23.7546C179.163 27.1709 180.122 31.3788 180.122 36.3783C180.122 41.3778 179.122 45.6273 177.122 49.1269C175.122 52.6265 172.498 55.2513 169.248 57.0011Z" fill="white"/>
        </svg>
      </div>
      <div className="relative top-[-100%] left-0 h-full w-full flex">
        {/* <div className="flex absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
          <svg xmlns="http://www.w3.org/2000/svg" width="91" height="182" viewBox="0 0 91 182" fill="none">
            <path d="M0 -0.000488281H84.6273V84.3721H0V-0.000488281Z" fill="#101010"/>
            <path d="M0 97.1172H84.6273V182H0V97.1172Z" fill="#101010"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="91" height="182" viewBox="91 0 91 182" fill="none">
           <path d="M97.3723 -0.000488281H136.245C161.374 -0.000488281 181.745 20.3708 181.745 45.4995C181.745 70.6283 161.374 90.9995 136.245 90.9995C161.374 90.9995 181.745 111.371 181.745 136.5C181.745 161.628 161.374 182 136.245 182H97.3723V-0.000488281Z" fill="#101010"/>
          </svg>
        </div>
        
        <div className="h-full w-[50%] bg-white-100 rounded-[2rem]"></div>
        <div className="h-full w-[50%] bg-white-100 rounded-[2rem]"></div> */}
      

      <svg className="absolute z-[10] min-w-[100vw] top-0 left-[50%] translate-x-[-50%] logo-svg w-auto h-screen" xmlns="http://www.w3.org/2000/svg" width="1920" height="943" viewBox="0 0 1920 943" fill="none">
        <g clipPath="url(#mask)">
        <rect className="w-[200%] translate-x-[-50%]" xmlns="http://www.w3.org/2000/svg" width="1920" height="943" fill="white"/>

        <g className="buenro-logo">
          <path  d="M869 381H953.627V465.372H869V381Z" fill="#101010"/>
          <path d="M869 478.117H953.627V563H869V478.117Z" fill="#101010"/>
          <path  d="M966.372 381H1005.24C1030.37 381 1050.74 401.371 1050.74 426.5C1050.74 451.628 1030.37 472 1005.24 472C1030.37 472 1050.74 492.371 1050.74 517.5C1050.74 542.628 1030.37 563 1005.24 563H966.372V381Z" fill="#101010"/>
        </g>
        </g>
        <defs xmlns="http://www.w3.org/2000/svg">
          <clipPath xmlns="http://www.w3.org/2000/svg" id="mask">
            <rect className="mask hidden" x="960" width="960" height="943" rx="80" fill="white"></rect>
            <rect className="mask hidden" width="960" height="943" rx="80" fill="white"></rect>
          </clipPath>
        </defs>
      </svg>
      </div>

    </div>
  )
}
