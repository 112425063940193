"use client"
import Image from "next/image";
import {Key, useContext} from "react"
// import { testimonialsData } from "../../../data/index";
// import { testimonialsButtons } from "../../../data/index";
import { TestimonialsContent } from "./TestimonialsContent";
import {TesTrigger} from "./testimonialsTrigger"
import  {ThemeContext}  from "../../../providers/index";

export const Testimonials = ({data}: any) => {
  // console.log(data);
  const {testimonialsButtons, testimonialsData} = data;
  // console.log(testimonialsButtons)
  const {lenisScroll}= useContext(ThemeContext);
  function scrollToSection(id:string) {
    // Using setTimeout to put the scroll action at the end of the event queue

    setTimeout(() => {
      const section:HTMLElement = document.getElementById(id)  as HTMLElement;;
      // if (footer) {
        // section.scrollIntoView({ behavior: "smooth" });
        lenisScroll.lenis.scrollTo(section)
        // setIsOpen(false)
      // }
    }, 0);
  }
  return (
    <>
    <section className={`bg-white-100 testimonials text-black ${testimonialsButtons.length ===6?"h-[600vh]":"h-[400vh]"} relative tablet:hidden`}>
      <div className="h-[100vh] w-[10rem] sticky top-0 buttons pl-[calc(100vw/12)] z-[200] flex flex-col justify-center text-regular30 gap-[.67rem]">
          {
            testimonialsButtons.map((button: { text: any; id: any; })=>{
              const {text,id} = button;
              return(
                <button key={id} data-name={text} className="text-left tes-button capitalize" onClick={()=>scrollToSection(text)}>{text}</button>
                )
            })
          }
      </div>
      {/* <div className="sticky h-screen z-[10] top-[0] mt-[-100vh]">
        <div className="right-[calc((100vw/12)-2rem)] absolute rounded-[4rem] h-auto border-[calc(100vh/12)] border-white-100 max-h-[calc(30rem+20vh)] top-[50%] translate-y-[-50%] between:rounded[calc(2rem+54px)]">
          <div className="w-[calc((100vw/12)*8)] h-[calc(100vh-16.6vh)]"></div>
        </div>
      </div> */}
      <div className="fixed-bg mt-[-100vh] sticky top-[0] px-[calc(100vw/12)] flex flex-row justify-between items-center">
        <div className="h-[100vh] w-[7rem] buttons flex flex-col justify-center text-regular30 gap-[.67rem]">
          {/* {
            testimonialsButtons.map((button)=>{
              const {text,id} = button;
              return(
                <button id={id} data-name={text} className="text-left tes-button" onClick={()=>scrollToSection(text)}>{text}</button>
                )
            })
          } */}
        </div>
        <div className={`frame-container w-[calc((100vw/12)*8)] ${testimonialsButtons.length ===6?"bg-red-200":"bg-red-gradient border-[.09rem] border-black"} pr-[0.4rem] h-[calc(13rem+29vh)] rounded-[2rem] flex justify-between `}>
        <div className="relative w-[57%] h-full overflow-hidden ">
          <div className="tes-scroll-content w-[26ch] absolute left-0 pl-[2.03rem] pr-[.2rem] top-[calc(((71vh-12.3rem)/2)*-1)] between:w-[23ch]">
            {testimonialsData.map((data: { text: string; paragraph: string; popColor: string; popText: string; bg: string; name: string; }, i: Key | null | undefined) => {
            return <TestimonialsContent key={i} props={data} />;
             })}

          </div>
        </div>

          <div className="images-container w-[43%] relative">
          {testimonialsData.map((data: { img: any; }, i: Key | null | undefined) => {
            const {img} = data;
            return (
              <div className="test-img-mask" key={i}>
              <Image
                className="w-[100%] origin-top absolute h-[16.8rem] top-[50%] rounded-[1.25rem] object-cover"
                src={img}
                alt=""
              />
            </div>
            );
           })}

          </div>
        </div>
      </div>

      <div className="tes-scroller-wrapper w-[calc((100%-((100vw/12)*3))/2)] opacity-0 absolute left-[calc((100vw/12)*3)] pl-[2.03rem] pr-[2.2rem] top-[0]">
        {testimonialsData.map((data: { bg: any; name: any; }, i: Key | null | undefined) => {
          const {bg,name}=data;
          return (
          <div className="tes-scroller h-screen w-full" key={i} data-color={bg} data-name={name} id={name}>

          </div>)
        })}

      </div>
    </section>
    <section className="bg-white-100">
      <div className={`test-bg-mobile hidden tablet:block p-[2rem] rounded-[2.9rem] w-[calc((100vw/12)*10)] mx-auto bg-red-200 ${testimonialsButtons.length ===6?"bg-red-200":"bg-red-gradient border-[.17rem] border-black"} mobile:w-full mobile:pt-[5rem] mobile:px-[1.2rem]`}>
      {testimonialsData.map((data: { text: any; paragraph: any; popColor: any; popText: any; bg: any; img: any; }, i: Key | null | undefined) => {
        const {text,paragraph,popColor,popText,bg,img}=data;
        // const splitedText = text.split(popText)
          return (
            <div key={i} className="moblile-tes-contain pb-[5rem]" data-color={bg}>
              <Image src={img} alt="" className="h-[20rem] object-cover rounded-[2rem] mobile:h-[calc(100vw-2.4rem)]"/>
              <div className="tes-content h-auto pt-[2rem] flex flex-col justify-between">
                <h6 className="text-[1.64rem] leading-[1.27] pb-[1.5rem]">
                 {
                  //  splitedText[0]}
                  //  <span className={popColor}>{popText}</span>
                  //  { splitedText[1]}
                  text
                  }
                 
                </h6>
                <h6 className="text-[.8rem] mobile:text-[.9rem]">
                  {paragraph}
                </h6>
              </div>

            </div>
          );
        })}

      </div>
      <TesTrigger/>

    </section>
    </>
  );
};
