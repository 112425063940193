interface TestimonialsContentProps{
  props:{
    text:string,
    paragraph:string,
    popColor:string,
    popText:string,
    bg:string,
    name:string,

  }
}

export const TestimonialsContent : React.FC<TestimonialsContentProps> = ({props})=>{
  const {text,paragraph,popColor,popText,bg,name}=props;
  const splitedText = text.split(popText)
  return(
    <div className="min-h-[15.6rem] h-screen tes-content h-auto flex flex-col justify-center">
          <h6 className="text-[1.64rem] leading-[1.27] pb-[3rem] between:text-[calc(1.2rem+.5vw)]">
            {popColor &&
              splitedText[0]}
              <span className={popColor}>{popText}</span>
              { splitedText[1]
              // text
            }
            {!popColor && text }
            
          </h6>
          <h6 className="text-[.63rem] between:text-[calc(.35rem+.5vw)]">
            {paragraph}
          </h6>
        </div>
  )
}