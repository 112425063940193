"use client";
import { useState, useEffect, useRef } from "react";
import gsap from "gsap";

export const Faq = ({long=false}) => {
  const [activeQ, setActiveQ] = useState<HTMLElement | null>(null);
  const [hoveredQ, setHoveredQ] = useState<HTMLElement | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const targetEl = e.target as HTMLElement;
    const totalHeight = targetEl.offsetHeight;
    const pHeight = targetEl.querySelector("p")?.offsetHeight || 0;
    const newHeight = totalHeight - pHeight - 1.5;
    setActiveQ((pre) => (pre === targetEl ? null : targetEl));
    // console.log(activeQ,);
  };
  const handleHover=(e: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
    const targetEl = e.target as HTMLElement;
    setHoveredQ(targetEl );
  }  
   const closeFAQ = () => {
    setActiveQ(null);
  };
  const handleLeave = ()=>{
    setHoveredQ(null)
  }

  const gsapRef = useRef(null);
  useEffect(() => {
    let ctx = gsap.context(() => {
      const faqs = gsap.utils.toArray<HTMLElement>(".faq-contain");
 

      faqs.forEach((faq) => {
        //  console.log(`calc(${faq.offsetHeight - faq.querySelector("p").offsetHeight}px - 1rem)`)
        gsap.to(faq.querySelector<HTMLElement>(".p-wrapper"), {
          height: () => (faq === activeQ ? "auto" : "0"),
          duration: 0.8,
          opacity: () => (faq === activeQ ? 1 : 0),
          onStart: () => {
            gsap.to(faq.querySelectorAll<HTMLElement>(".ver-line"), {
              scale: () => (faq === activeQ ? 0 : 1),
              duration: 0.7,
              ease: "power2.out",
            });
          },
          ease: "expo.out",
        });
        gsap.to(faq, {
          opacity: () =>
            faq === activeQ || faq===hoveredQ ? "1": activeQ === null && !hoveredQ ? "1" : ".4",
          duration: 0.8,
          onStart: () => {
            gsap.to(faq.querySelectorAll<HTMLElement>(".plus-line"), {
              background: () => (faq === activeQ ||faq ===hoveredQ  ? "#fff" : "#000"),
              duration: 0.6,
              ease: "power3.out",
            });
          },
          ease: "expo.out",
        });
        gsap.to(faq.querySelector<HTMLElement>(".faq-plus"), {
          backgroundColor: () => (faq === activeQ ||faq ===hoveredQ ? "#000000" : "transparent"),
          duration: 0.6,
          ease: "power3.out",
        });
      });
    }, gsapRef);
  }, [activeQ,hoveredQ]);

  return (
    <section
      className="flex min-h-screen relative px-global bg-white-100 pt-[5.2rem] pb-[5rem] justify-between tablet:gap-[2rem] tablet:flex-col tablet:justify-start mobile:px-[calc(100vw/16)]"
      ref={gsapRef}
    >
      <div className="">
        <h6 className="text-regular75 leading-[1.5] w-[9ch] mobile:text-[2.3rem] mobile:pr-[1.3rem]">
          Frequently Asked Questions
        </h6>
      </div>
      <div
        className="absolute w-full h-full top-[0] left-[0]"
        onClick={() => closeFAQ()}
      ></div>
      <div>
        <div className="flex flex-col w-[calc(100vw/12*6)] relative z-[10] tablet:w-[100%]">
          <div
            className="pt-[.5rem] cursor-pointer pb-[1.05rem] border-b-[1px] border-white-900 mb-[.5rem] faq-contain overflow-hidden 
            mobile:pr-[1rem] mobile:pb-[1.4rem]"
            onClick={(e) => handleClick(e)}
            onMouseOver={(e) => handleHover(e)}
            onMouseLeave={handleLeave}
          >
            <div className="flex justify-between relative pointer-events-none">
              <h6 className="text-regular30  pointer-events-none mobile:text-[.92rem] mobile:pr-[1.3rem]">
                How can I be part of Buenro?
              </h6>
              <div className="faq-plus w-[1.69rem] h-[1.69rem] rounded-full text-black absolute top-[50%] right-[0%] translate-y-[-50%] flex justify-center items-center mobile:right-[-5%]">
                <div className="plus-line rounded-full w-[.57rem] h-[.0676rem] bg-white-100"></div>
                <div className="plus-line ver-line rounded-full w-[.0676rem] h-[.57rem] absolute bg-white-100"></div>
              </div>
            </div>
            <div className="pointer-events-none p-wrapper opacity-0 h-[0]">
              <p className="text-[.634rem] pt-[.85rem] text-[#1f1f1f] leading-[1.55] pointer-events-none	">
                 You can request access through our waiting list. Once we release our app, we’ll ask you to create a profile and tell us more about yourself.
              </p>
            </div>
          </div>
          <div
            className="cursor-pointer pt-[.5rem] pb-[1.05rem] border-b-[1px] border-white-900 mb-[.5rem] faq-contain overflow-hidden 
            mobile:pr-[1rem] mobile:pb-[1.4rem]"
            onClick={(e) => handleClick(e)}
            onMouseOver={(e) => handleHover(e)}
            onMouseLeave={handleLeave}
          >
            <div className="flex justify-between relative pointer-events-none">
              <h6 className="text-regular30  pointer-events-none  mobile:text-[.92rem] mobile:pr-[1.3rem]">
                Can anyone join Buenro?
              </h6>
              <div className="faq-plus w-[1.69rem] h-[1.69rem] rounded-full text-black absolute top-[50%] right-[0%] translate-y-[-50%] flex justify-center items-center mobile:right-[-5%]">
                <div className="plus-line rounded-full w-[.57rem] h-[.0676rem] bg-white-100"></div>
                <div className="plus-line ver-line rounded-full w-[.0676rem] h-[.57rem] absolute bg-white-100"></div>
              </div>
            </div>
            <div className="pointer-events-none p-wrapper opacity-0 h-[0]">
              <p className="text-[.634rem] pt-[.85rem] text-[#1f1f1f] leading-[1.55] pointer-events-none	">
                Absolutely! Everyone is welcome to Buenro. However, we want to
                keep the magic and feeling of family within the community. For
                that reason, we have limited access to new members.
               
              </p>
            </div>
          </div>
          <div
            className="cursor-pointer pt-[.5rem] pb-[1.05rem] border-b-[1px] border-white-900 mb-[.5rem] faq-contain overflow-hidden 
            mobile:pr-[1rem] mobile:pb-[1.4rem]"
            onClick={(e) => handleClick(e)}
            onMouseOver={(e) => handleHover(e)}
            onMouseLeave={handleLeave}
          >
            <div className="flex justify-between relative pointer-events-none">
              <h6 className="text-regular30 leading-[1.5]  pointer-events-none  mobile:text-[.92rem] mobile:pr-[1.3rem]">
              How long will I be on the waitlist for?
              </h6>
              <div className="faq-plus w-[1.69rem] h-[1.69rem] rounded-full text-black absolute top-[50%] right-[0%] translate-y-[-50%] flex justify-center items-center mobile:right-[-5%]">
                <div className="plus-line rounded-full w-[.57rem] h-[.0676rem] bg-white-100"></div>
                <div className="plus-line ver-line rounded-full w-[.0676rem] h-[.57rem] absolute bg-white-100"></div>
              </div>
            </div>
            <div className="pointer-events-none p-wrapper opacity-0 h-[0]">
              <p className="text-[.634rem] pt-[.85rem] text-[#1f1f1f] leading-[1.55] pointer-events-none	">
                At the moment, we are receiving a high volume of applications,
                so we ask for your patience. We are doing our best to grant you
                access as quickly as possible. If you know someone who is
                already inside, ask them to refer you to skip the line ahead.
              </p>
            </div>
          </div>
          <div
            className="cursor-pointer pt-[.5rem] pb-[1.05rem] border-b-[1px] border-white-900 mb-[.5rem] faq-contain overflow-hidden 
            mobile:pr-[1rem] mobile:pb-[1.4rem]"
            onClick={(e) => handleClick(e)}
            onMouseOver={(e) => handleHover(e)}
            onMouseLeave={handleLeave}
          >
            <div className="flex justify-between relative pointer-events-none">
              <h6 className="text-regular30  pointer-events-none  mobile:text-[.92rem] mobile:pr-[1.3rem]">
                Do I need to be a remote worker or entrepreneur?
              </h6>
              <div className="faq-plus w-[1.69rem] h-[1.69rem] rounded-full text-black absolute top-[50%] right-[0%] translate-y-[-50%] flex justify-center items-center mobile:right-[-5%]">
                <div className="plus-line rounded-full w-[.57rem] h-[.0676rem] bg-white-100"></div>
                <div className="plus-line ver-line rounded-full w-[.0676rem] h-[.57rem] absolute bg-white-100"></div>
              </div>
            </div>
            <div className="pointer-events-none p-wrapper opacity-0 h-[0]">
              <p className="text-[.634rem] pt-[.85rem] text-[#1f1f1f] leading-[1.55] pointer-events-none	">
                No, we&apos;re not looking for a specific professional profile.
                While most of our members work remotely, no matter what your
                profession is, you have a place in our home.
              </p>
            </div>
          </div>
          {/* <div
            className="cursor-pointer pt-[.5rem] pb-[1.05rem] border-b-[1px] border-white-900 mb-[.5rem] faq-contain overflow-hidden mobile:pr-[1rem]"
            onClick={(e) => handleClick(e)}
            onMouseOver={(e) => handleHover(e)}
          >
            <div className="flex justify-between relative pointer-events-none">
              <h6 className="text-regular30  pointer-events-none	">
                How does the process of booking a home work?
              </h6>
              <div className="faq-plus w-[1.69rem] h-[1.69rem] rounded-full text-black absolute top-[50%] right-[0%] translate-y-[-50%] flex justify-center items-center mobile:right-[-6%]">
                <div className="plus-line rounded-full w-[.57rem] h-[.0676rem] bg-white-100"></div>
                <div className="plus-line ver-line rounded-full w-[.0676rem] h-[.57rem] absolute bg-white-100"></div>
              </div>
            </div>
            <div className="pointer-events-none p-wrapper opacity-0 h-[0]">
              <p className="text-[.634rem] pt-[.85rem] text-[#1f1f1f] leading-[1.55] pointer-events-none	">
                There are two options: you can initiate a home yourself and
                invite people to join you, or you can directly book a room in
                one of the homes that match your needs.
              </p>
            </div>
          </div>
          <div
            className="cursor-pointer pt-[.5rem] pb-[1.05rem] border-b-[1px] border-white-900 mb-[.5rem] faq-contain overflow-hidden mobile:pr-[1rem]"
            onClick={(e) => handleClick(e)}
          >
            <div className="flex justify-between relative pointer-events-none">
              <h6 className="text-regular30  pointer-events-none	">
                What is included in my booking?
              </h6>
              <div className="faq-plus w-[1.69rem] h-[1.69rem] rounded-full text-black absolute top-[50%] right-[0%] translate-y-[-50%] flex justify-center items-center mobile:right-[-6%]">
                <div className="plus-line rounded-full w-[.57rem] h-[.0676rem] bg-white-100"></div>
                <div className="plus-line ver-line rounded-full w-[.0676rem] h-[.57rem] absolute bg-white-100"></div>
              </div>
            </div>
            <div className="pointer-events-none p-wrapper opacity-0 h-[0]">
              <p className="text-[.634rem] pt-[.85rem] text-[#1f1f1f] leading-[1.55] pointer-events-none	">
                In your Buenro Home experience, you can expect the following to
                be covered: comfortable accommodation, high-speed WiFi,
                dedicated working spaces and utilities (water, electricity,
                fresh linens and towels)
                <br />
                Also note that the following are not included: transportation,
                food and drinks, activities or events, local tourism taxes and
                fees, additional services (ie: extra cleaning) or deposits.
              </p>
            </div>
          </div>
          <div
            className="cursor-pointer pt-[.5rem] pb-[1.05rem] border-b-[1px] border-white-900 mb-[.5rem] faq-contain overflow-hidden mobile:pr-[1rem]"
            onClick={(e) => handleClick(e)}
          >
            <div className="flex justify-between relative pointer-events-none">
              <h6 className="text-regular30  pointer-events-none	">
                What’s the maximum length of stay?
              </h6>
              <div className="faq-plus w-[1.69rem] h-[1.69rem] rounded-full text-black absolute top-[50%] right-[0%] translate-y-[-50%] flex justify-center items-center mobile:right-[-6%]">
                <div className="plus-line rounded-full w-[.57rem] h-[.0676rem] bg-white-100"></div>
                <div className="plus-line ver-line rounded-full w-[.0676rem] h-[.57rem] absolute bg-white-100"></div>
              </div>
            </div>
            <div className="pointer-events-none p-wrapper opacity-0 h-[0]">
              <p className="text-[.634rem] pt-[.85rem] text-[#1f1f1f] leading-[1.55] pointer-events-none	">
                Currently, 3 months is the longest booking period available.
                That being said, you can keep extending your stay as much as you
                want if there’s availability.
              </p>
            </div>
          </div>
          <div
            className="cursor-pointer pt-[.5rem] pb-[1.05rem] border-b-[1px] border-white-900 mb-[.5rem] faq-contain overflow-hidden mobile:pr-[1rem]"
            onClick={(e) => handleClick(e)}
          >
            <div className="flex justify-between relative pointer-events-none">
              <h6 className="text-regular30  pointer-events-none	">
                With whom will I share the home?
              </h6>
              <div className="faq-plus w-[1.69rem] h-[1.69rem] rounded-full text-black absolute top-[50%] right-[0%] translate-y-[-50%] flex justify-center items-center mobile:right-[-6%]">
                <div className="plus-line rounded-full w-[.57rem] h-[.0676rem] bg-white-100"></div>
                <div className="plus-line ver-line rounded-full w-[.0676rem] h-[.57rem] absolute bg-white-100"></div>
              </div>
            </div>
            <div className="pointer-events-none p-wrapper opacity-0 h-[0]">
              <p className="text-[.634rem] pt-[.85rem] text-[#1f1f1f] leading-[1.55] pointer-events-none	">
                You&apos;ll be sharing the house with amazing like-minded people
                who are there for the same reason you are. You’ll always be able
                to check which people will be in the home before making the
                reservation. As soon as your booking is confirmed you will get
                access to a shared chat where you can get to know everyone and
                coordinate before you arrive.
              </p>
            </div>
          </div>
          <div
            className="cursor-pointer pt-[.5rem] pb-[1.05rem] border-b-[1px] border-white-900 mb-[.5rem] faq-contain overflow-hidden mobile:pr-[1rem]"
            onClick={(e) => handleClick(e)}
          >
            <div className="flex justify-between relative pointer-events-none">
              <h6 className="text-regular30  pointer-events-none	">
                Are there any home rules?
              </h6>
              <div className="faq-plus w-[1.69rem] h-[1.69rem] rounded-full text-black absolute top-[50%] right-[0%] translate-y-[-50%] flex justify-center items-center mobile:right-[-6%]">
                <div className="plus-line rounded-full w-[.57rem] h-[.0676rem] bg-white-100"></div>
                <div className="plus-line ver-line rounded-full w-[.0676rem] h-[.57rem] absolute bg-white-100"></div>
              </div>
            </div>
            <div className="pointer-events-none p-wrapper opacity-0 h-[0]">
              <p className="text-[.634rem] pt-[.85rem] text-[#1f1f1f] leading-[1.55] pointer-events-none	">
                Yes! Home rules are important to protect the community, comply
                with local regulations, and ensure a certain level of safety.
                You can view the home rules in the booking process. All internal
                home rules will be consistent with Buenro policies and
                conditions.
              </p>
            </div>
          </div>
          <div
            className="cursor-pointer pt-[.5rem] pb-[1.05rem] border-b-[1px] border-white-900 mb-[.5rem] faq-contain overflow-hidden mobile:pr-[1rem]"
            onClick={(e) => handleClick(e)}
          >
            <div className="flex justify-between relative pointer-events-none">
              <h6 className="text-regular30  pointer-events-none	">
                Are outside visitors allowed in the house?
              </h6>
              <div className="faq-plus w-[1.69rem] h-[1.69rem] rounded-full text-black absolute top-[50%] right-[0%] translate-y-[-50%] flex justify-center items-center mobile:right-[-6%]">
                <div className="plus-line rounded-full w-[.57rem] h-[.0676rem] bg-white-100"></div>
                <div className="plus-line ver-line rounded-full w-[.0676rem] h-[.57rem] absolute bg-white-100"></div>
              </div>
            </div>
            <div className="pointer-events-none p-wrapper opacity-0 h-[0]">
              <p className="text-[.634rem] pt-[.85rem] text-[#1f1f1f] leading-[1.55] pointer-events-none	">
                Visitors are welcome during the day as long as your initiator
                and roommates agree. For overnight visitors, simply add them as
                your guest in the booking area for free. It helps keep track and
                makes it easier for your roommates.
              </p>
            </div>
          </div>
          <div
            className="cursor-pointer pt-[.5rem] pb-[1.05rem] border-b-[1px] border-white-900 mb-[.5rem] faq-contain overflow-hidden mobile:pr-[1rem]"
            onClick={(e) => handleClick(e)}
          >
            <div className="flex justify-between relative pointer-events-none">
              <h6 className="text-regular30  pointer-events-none	">
                Can I bring my kids?
              </h6>
              <div className="faq-plus w-[1.69rem] h-[1.69rem] rounded-full text-black absolute top-[50%] right-[0%] translate-y-[-50%] flex justify-center items-center mobile:right-[-6%]">
                <div className="plus-line rounded-full w-[.57rem] h-[.0676rem] bg-white-100"></div>
                <div className="plus-line ver-line rounded-full w-[.0676rem] h-[.57rem] absolute bg-white-100"></div>
              </div>
            </div>
            <div className="pointer-events-none p-wrapper opacity-0 h-[0]">
              <p className="text-[.634rem] pt-[.85rem] text-[#1f1f1f] leading-[1.55] pointer-events-none	">
                No, sorry. In order to maintain a working atmosphere, only
                adults are allowed in our homes - for now! we’re looking forward
                to opening soon family-friendly Buenro Homes
              </p>
            </div>
          </div>
          <div
            className="cursor-pointer pt-[.5rem] pb-[1.05rem] border-b-[1px] border-white-900 mb-[.5rem] faq-contain overflow-hidden mobile:pr-[1rem]"
            onClick={(e) => handleClick(e)}
          >
            <div className="flex justify-between relative pointer-events-none">
              <h6 className="text-regular30  pointer-events-none	">
                How does your pricing work?
              </h6>
              <div className="faq-plus w-[1.69rem] h-[1.69rem] rounded-full text-black absolute top-[50%] right-[0%] translate-y-[-50%] flex justify-center items-center mobile:right-[-6%]">
                <div className="plus-line rounded-full w-[.57rem] h-[.0676rem] bg-white-100"></div>
                <div className="plus-line ver-line rounded-full w-[.0676rem] h-[.57rem] absolute bg-white-100"></div>
              </div>
            </div>
            <div className="pointer-events-none p-wrapper opacity-0 h-[0]">
              <p className="text-[.634rem] pt-[.85rem] text-[#1f1f1f] leading-[1.55] pointer-events-none	">
                Our prices are dynamic and change depending on the home, the
                season, the room type, length of stay and time of booking.
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};
