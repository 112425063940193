import person2 from "../../public/assets/1.jpg";
import person1 from "../../public/assets/test3.jpg";
import person3 from "../../public/assets/person3.jpg";
import person4 from "../../public/assets/person4.jpg";
import member1 from "../../public/assets/users/member1.jpg";
import member2 from "../../public/assets/users/new.jpg";
import person from "../../public/assets/users/person1.jpg";
import heroCar1 from "../../public/assets/landingPage/heroCar1.jpg";
import heroCar2 from "../../public/assets/landingPage/heroCar2.jpg";
import heroCar3 from "../../public/assets/landingPage/heroCar3.jpg";
import heroCar4 from "../../public/assets/landingPage/heroCar4.jpg";
import usa from "../../public/assets/landingPage/us.png";
import ca from "../../public/assets/landingPage/ca.png";
import en from "../../public/assets/landingPage/en.png";

// feature card images imports

import featureCar1Villa1 from "../../public/assets/landingPage/featuresCarousel/villa1.jpg";
import featureCar1Villa2 from "../../public/assets/landingPage/featuresCarousel/villa2.jpg";
import featureCar1Villa3 from "../../public/assets/landingPage/featuresCarousel/villa3.jpg";
import featureCar1Villa4 from "../../public/assets/landingPage/featuresCarousel/villa4.jpg";

//2 feature card
import featureCar2Image1 from "../../public/assets/landingPage/featuresCarousel/featureCard2Img1.jpg";
import featureCar2Image2 from "../../public/assets/landingPage/featuresCarousel/featureCard2Img2.jpg";
import featureCar2Image3 from "../../public/assets/landingPage/featuresCarousel/featureCard2Img3.jpg";

//3 feature card
import featureCar3Image1 from "../../public/assets/landingPage/featuresCarousel/featureCard3Img1.jpg";
import featureCar3Image2 from "../../public/assets/landingPage/featuresCarousel/featureCard3Img2.jpg";
import featureCar3Image3 from "../../public/assets/landingPage/featuresCarousel/featureCard3Img3.jpg";
import featureCar3Image4 from "../../public/assets/landingPage/featuresCarousel/featureCard3Img4.jpg";

import slideInCard1 from "../../public/assets/landingPage/rotateCard1.jpg";
import slideInCard2 from "../../public/assets/landingPage/rotateCard2.jpg";

import testimonialImage1 from "../../public/assets/landingPage/testimonials/testimonial1.jpg";
import testimonialImage2 from "../../public/assets/landingPage/testimonials/testimonial2.jpg";
import testimonialImage3 from "../../public/assets/landingPage/testimonials/testimonial3.jpg";
import testimonialImage4 from "../../public/assets/landingPage/testimonials/testimonial4.jpg";
// import testimonialImage5 from "../../public/assets/landingPage/testimonials/testimonial5.jpg";
// import testimonialImage6 from "../../public/assets/landingPage/testimonials/testimonial6.jpg";


import testimonialEv1 from "../../public/assets/events/test3.jpg";
import testimonialEv2 from "../../public/assets/events/props1.jpg";
import testimonialEv3 from "../../public/assets/events/test1.jpg";
import testimonialEv4 from "../../public/assets/events/test2.jpg";



import row1 from "../../public/assets/events/row/1.jpg"
import row2 from "../../public/assets/events/row/2.jpg"
import row3 from "../../public/assets/events/row/3.jpg"
import row4 from "../../public/assets/events/row/4.jpg"
import row5 from "../../public/assets/events/row/5.jpg"
import row6 from "../../public/assets/events/row/6.jpg"
import row7 from "../../public/assets/events/row/7.jpg"
import row8 from "../../public/assets/events/row/8.jpg"
import row9 from "../../public/assets/events/row/9.jpg"
import row12 from "../../public/assets/events/row/12.jpg"
import row13 from "../../public/assets/events/row/13.jpg"
import row17 from "../../public/assets/events/row/17.jpg"

//icons
const arrowDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
  >
    <path
      d="M16.1934 7C16.1934 6.30964 15.6337 5.75 14.9434 5.75C14.253 5.75 13.6934 6.30964 13.6934 7L16.1934 7ZM14.0595 22.8839C14.5476 23.372 15.3391 23.372 15.8272 22.8839L23.7822 14.9289C24.2703 14.4408 24.2703 13.6493 23.7822 13.1612C23.294 12.673 22.5026 12.673 22.0144 13.1612L14.9434 20.2322L7.87229 13.1612C7.38414 12.673 6.59268 12.673 6.10452 13.1612C5.61637 13.6493 5.61637 14.4408 6.10452 14.9289L14.0595 22.8839ZM13.6934 7L13.6934 22L16.1934 22L16.1934 7L13.6934 7Z"
      fill="black"
    />
  </svg>
);

import { StaticImageData } from "next/image";

//hero carousel
export const heroCar: {
  src: StaticImageData;
  color: string;
  counter: number;
  text: string;
  hexcode: string;
}[] = [
  {
    src: heroCar1,
    color: "text-green-900",
    counter: 0,
    text: "home",
    hexcode: "#BFFFDA",
  },
  {
    src: heroCar2,
    color: "text-blue-900",
    counter: 1,
    text: "productive",
    hexcode: "#CCF2FF",
  },
  {
    src: heroCar3,
    color: "text-red-900",
    counter: 2,
    text: "belonging",
    hexcode: "#FFCCD9",
  },
  {
    src: heroCar4,
    color: "text-yellow-900",
    counter: 3,
    text: "active",
    hexcode: "#FFFABF",
  },
];

//feature cards carousel
export const featuresCar1: {
  src: StaticImageData;
  color: string;
  counter: number;
  text: string;
  hexcode: string;
}[] = [
  {
    src: featureCar1Villa1,
    color: "text-green-900",
    counter: 0,
    text: "home",
    hexcode: "#BFFFDA",
  },
  {
    src: featureCar1Villa2,
    color: "text-blue-900",
    counter: 1,
    text: "productive",
    hexcode: "#CCF2FF",
  },
  {
    src: featureCar1Villa3,
    color: "text-red-900",
    counter: 2,
    text: "belonging",
    hexcode: "#FFCCD9",
  },
  {
    src: featureCar1Villa4,
    color: "text-yellow-900",
    counter: 3,
    text: "active",
    hexcode: "#FFFABF",
  },
];

export const featuresCar3: {
  src: StaticImageData;
  color: string;
  counter: number;
  text: string;
  hexcode: string;
}[] = [
  {
    src: featureCar3Image1,
    color: "text-green-900",
    counter: 0,
    text: "home",
    hexcode: "#BFFFDA",
  },
  {
    src: featureCar3Image2,
    color: "text-blue-900",
    counter: 1,
    text: "productive",
    hexcode: "#CCF2FF",
  },
  {
    src: featureCar3Image3,
    color: "text-red-900",
    counter: 2,
    text: "belonging",
    hexcode: "#FFCCD9",
  },
  {
    src: featureCar3Image4,
    color: "text-red-900",
    counter: 3,
    text: "belonging",
    hexcode: "#FFCCD9",
  },
];

export const featuresCar2: {
  src: StaticImageData;
  color: string;
  counter: number;
  text: string;
  hexcode: string;
}[] = [
  {
    src: featureCar2Image1,
    color: "text-green-900",
    counter: 0,
    text: "home",
    hexcode: "#BFFFDA",
  },
  {
    src: featureCar2Image2,
    color: "text-blue-900",
    counter: 1,
    text: "productive",
    hexcode: "#CCF2FF",
  },
  {
    src: featureCar2Image3,
    color: "text-red-900",
    counter: 2,
    text: "belonging",
    hexcode: "#FFCCD9",
  },
  {
    src: featureCar2Image2,
    color: "text-blue-900",
    counter: 3,
    text: "productive",
    hexcode: "#CCF2FF",
  },
];

export const membersData: {
  src: StaticImageData;
  name: string;
  title: string;
  front: Boolean;
  flag:StaticImageData,
}[] = [
  {
    src: member1,
    name: "Sophie P.",
    title: "Digital Nomad",
    front: false,
    flag: en,
  },
  {
    src: member2,
    name: "Carlos M.",
    title: "Remote Designer",
    front: true,
    flag: usa,

  },
  {
    src: person,
    name: "Emily S.",
    title: "Solo Traveler",
    front: false,
    flag: ca,

  },
];
type titleType={
  text:string;
  pop:boolean;
}
export const slideInData: {
  img1: StaticImageData;
  img2: StaticImageData;
  title:titleType[];
  paragraph: string;
} = {
  img1: slideInCard1,
  img2: slideInCard2,
  title: [{text:"It’s people ",pop:false},{text:"that make a",pop:true},{text:" house feel like home", pop:false}],
  paragraph:
    "We’ve discovered the key to a global lifestyle: it’s all about community",

};

export const interestsData: {
  name: string;
  percent: number;
  bg: string;
  border: string;
}[] = [
  {
    name: "adventure",
    percent: 75,
    bg: "bg-green-200",
    border: "border-green-550",
  },
  {
    name: "surfing",
    percent: 73,
    bg: "bg-yellow-120",
    border: "border-yellow-650",
  },
  { name: "gaming", percent: 70, bg: "bg-red-120", border: "border-red-550" },
  {
    name: "reading",
    percent: 65,
    bg: "bg-violet-80",
    border: "border-violet-500",
  },
];
// export const testimonialsData: {
//   img: StaticImageData;
//   text: string;
//   paragraph: string;
//   bg: string;
//   popText: string;
//   popColor: string;
//   name: string;
// }[] = [
//   {
//     img: testimonialImage1,
//     text: "I love everybody here. It’s like we’ve been friends forever.",
//     paragraph:
//       "Forge relationships that last a lifetime thanks to our Buenro human compatibility analysis.",
//     popText: "love",
//     popColor: "text-red-900",
//     bg: "#FFCCD9",
//     name:"connect",
//   },
//   {
//     img: testimonialImage2,
//     text: "I found a community I trust & met the perfect partner.",
//     paragraph:
//       "Meet your next co-founder, teammate, investor or client in a community where trust is cultivated through friend-vetted connections.",
//     popText: "trust",
//     popColor: "text-yellow-900",
//     bg: "#FFFABF",
//     name:"venture",
//   },
//   {
//     img: testimonialImage3,
//     text: "I found the courage. I would have never tried this alone.",
//     paragraph:
//       "Feel safe to take a leap of faith. With a supportive community by your side; step into the unfamiliar, foster growth, and push your boundaries.",
//     popText: "courage",
//     popColor: "text-blue-900",
//     bg: "#CCF2FF",
//     name:"grow"
//   },
//   {
//     img: testimonialImage4,
//     text: "Sharing my passion is the greatest gift, so fulfilling.",
//     paragraph:
//       "Share your skills with housemates and more: Host workshops, exchange languages, cook, or coach. Whether free or for a fee, let your enthusiasm shine.",
//     popText: "Sharing",
//     popColor: "text-green-900",
//     bg: "#CCFFE1",
//     name:"contribute",
//   },
//   {
//     img: testimonialImage5,
//     text: "No matter how far I travel, I always find my tribe.",
//     paragraph:
//       "Treasure the bonds you have. Buenro will keep your friends close by simplifying travel and suggesting shared accommodation to ensure your circles intertwine.",
//     popText: "tribe.",
//     popColor: "text-red-700",
//     bg: "#E0E0FF",
//     name:"gratitude"
//   },
//   {
//     img: testimonialImage6,
//     text: "Life's real now: traded scrolling for genuine conversations.",
//     paragraph:
//       "Buenro is the app for the real world. Embrace the joy of authentic human connection and co-create a sense of belonging.",
//     popText: "real",
//     popColor: "text-red-900",
//     bg: "#FFCCD9",
//     name:"enjoy",
//   },
// ];
// export const testimonialsButtons: {
//   text: string;
//   id: string;
// }[] = [
//   {

//     text: "connect",
//     id:"tesb01",
//   },
//   {

//     text: "venture",
//     id:"tesb02",
//   },
//   {

//     text: "grow",
//     id:"tesb03",
//   },
//   {

//     text: "contribute",
//     id:"tesb04",
//   },
//   {

//     text: "gratitude",
//     id:"tesb05",
//   },
//   {

//     text: "enjoy",
//     id:"tesb06",
//   },
// ];
export const testimonialsData: {
  img: StaticImageData;
  text: string;
  paragraph: string;
  bg: string;
  popText: string;
  popColor: string;
  name: string;
}[] = [
  {
    img: testimonialImage1,
    text: "“I love everybody here. It’s like we’ve been friends forever.”",
    paragraph:
      "Invite or join others for real-life plans anywhere you go — split a 10-bedroom summer villa, organize an impromptu dinner, or share a sailing trip.",
    popText: "",
    popColor: "",
    bg: "linear-gradient(111deg, #FFF 13.66%, #FFE0E8 63.68%)",
    name:"events",
  },
  {
    img: testimonialImage2,
    text: "“I found a community I trust & met the perfect partner.”",
    paragraph:
      "Find a place to gather in our global portfolio of over 50,000 homes - and pay only for your room.",
    popText: "",
    popColor: "",
    bg: "linear-gradient(111deg, #FFF 13.66%, #FFFFE0 63.68%)",
    name:"share homes",
  },
  {
    img: testimonialImage3,
    text: "“I found the courage. I would have never tried this alone.”",
    paragraph:
      "Plans move forward only when targets like minimum attendees or funding goals are met, eliminating risks and upfront costs.",
    popText: "",
    popColor: "",
    bg: "linear-gradient(111deg, #FFF 13.66%, #E0E5FF 63.68%)",
    name:"cost splitting"
  },
  {
    img: testimonialImage4,
    text: "“Sharing my passion is the greatest gift, so fulfilling.”",
    paragraph:
      "Share your skills with housemates and more: Host workshops, exchange languages, cook, or coach. Whether free or for a fee, let your enthusiasm shine.",
    popText: "Sharing",
    popColor: "text-green-900",
    bg: "#CCFFE1",
    name:"contribute",
  },
  // {
  //   img: testimonialImage5,
  //   text: "“No matter how far I travel, I always find my tribe.”",
  //   paragraph:
  //     "Treasure the bonds you have. Buenro will keep your friends close by simplifying travel and suggesting shared accommodation to ensure your circles intertwine.",
  //   popText: "tribe.",
  //   popColor: "text-red-700",
  //   bg: "#E0E0FF",
  //   name:"gratitude"
  // },
  // {
  //   img: testimonialImage6,
  //   text: "“Life's real now: traded scrolling for genuine conversations.”",
  //   paragraph:
  //     "Buenro is the app for the real world. Embrace the joy of authentic human connection and co-create a sense of belonging.",
  //   popText: "real",
  //   popColor: "text-red-900",
  //   bg: "#FFCCD9",
  //   name:"enjoy",
  // },
];

export const testimonialsButtons: {
  text: string;
  id: string;
}[] = [
  {

    text: "connect",
    id:"tesb01",
  },
  {

    text: "venture",
    id:"tesb02",
  },
  {

    text: "grow",
    id:"tesb03",
  },
  {

    text: "contribute",
    id:"tesb04",
  },
  {

    text: "gratitude",
    id:"tesb05",
  },
  {

    text: "enjoy",
    id:"tesb06",
  },
];

export const testimonialsDataEvents: {
  img: StaticImageData;
  text: string;
  paragraph: string;
  bg: string;
  popColor: string;
  name: string;
}[] = [
  // {
  //   img: testimonialEv1,
  //   text: "Swap likes for vibes",
  //   paragraph:
  //     "Invite or join others for real-life plans anywhere you go — split a 10-bedroom summer villa, organize an impromptu dinner, or share a sailing trip.",

  //   popColor: "",
  //   bg: "linear-gradient(111deg, #FFF 13.66%, #FFE0E8 63.68%)",
  //   name:"events",
  // },
  {
    img: testimonialEv2,
    text: "Real Homes for Real Connection",
    paragraph:
      "Find a place to gather in our global portfolio of over 50,000 homes - and pay only for your room.",

    popColor: "",
    bg: "linear-gradient(111deg, #FFF 13.66%, #FFFFE0 63.68%)",
    name:"share homes",
  },
  {
    img: testimonialEv3,
    text: "Share the experience, split the cost.",
    paragraph:
      "Plans move forward only when targets like minimum attendees or funding goals are met, eliminating risks and upfront costs.",

    popColor: "",
    bg: "linear-gradient(111deg, #FFF 13.66%, #E0E5FF 63.68%)",
    name:"cost splitting"
  },
  {
    img: testimonialEv4,
    text: "Future Plans Over Past Posts",
    paragraph:
      "Connect with what your friends are planning, not what you've missed.",

    popColor: "",
    bg: "linear-gradient(111deg, #FFF 13.66%, #E0F5FF 63.68%)",
    name:"sync plans",
  },
];
export const testimonialsButtonsEvents: {
  text: string;
  id: string;
}[] = [
  // {

  //   text: "events",
  //   id:"tesb01",
  // },
  {

    text: "share homes",
    id:"tesb02",
  },
  {

    text: "cost splitting",
    id:"tesb03",
  },
  {

    text: "sync plans",
    id:"tesb04",
  },

];



export const featuresData: {
  name: string;
  percent: number;
  bg: string;
  border: string;
}[] = [
  {
    name: "adventure",
    percent: 75,
    bg: "bg-green-200",
    border: "border-green-550",
  },
  {
    name: "surfing",
    percent: 73,
    bg: "bg-yellow-120",
    border: "border-yellow-650",
  },
  { name: "gaming", percent: 70, bg: "bg-red-120", border: "border-red-550" },
  {
    name: "reading",
    percent: 65,
    bg: "bg-violet-80",
    border: "border-violet-500",
  },
];


// Events
export const EvRow=[
  // {src:row5, key:"r01"},
  {src:row9, key:"r06"},
  {src:row1, key:"r07"},
  {
  src:row2,
  key:"r01",
  },
  {src:row17, key:"r02"},
  {src:row5, key:"r03"},
  {src:row3, key:"r04"},
  {src:row12, key:"r05"},
  // {src:row6, key:"r08"},
]

export const PinningScreensDt= [
  // {
  //   src:row7,
  //   leftText:"Create",
  //   rightText:"Events",
  //   key:"ps01",
  // },
  {
    src:row17,
    leftText:"Sync Trips",
    rightText:"& Plans",
    key:"ps02",
  },
  {
    src:row13,
    leftText:"Book &",
    rightText:"Share",
    key:"ps03",
  },
]