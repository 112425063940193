"use client"
import { EvRow } from "@/app/data"
import { useContext } from "react";
import Image from "next/image";
import gsap from "gsap"
import Sticky from "../Other/Sticky"
import SlideUp from "../Other/slideUpAnimation"
import { ThemeContext } from "../../providers/index";

export const SyncRow = () => {
  const { lenisScroll } = useContext(ThemeContext);
  function handleScrollToFooter() {
    // Using setTimeout to put the scroll action at the end of the event queue
    setTimeout(() => {
      const footer = document.getElementById("footer");
      console.log(footer);
      if (footer) {
        lenisScroll.lenis.scrollTo('#footer')
        // footer.scrollIntoView({ behavior: "smooth" });
        // lenisScroll.scrollTo(footer)
        // console.log(lenisScroll.lenis.scrollTo)
      }
    }, 0);
  }
  const hoverIn = () => {
    gsap.to(".arrow-right", {
      x: "0.3rem",
      duration: .6,
      ease: "expo.out"
    })
    gsap.to(".cta-text", {
      yPercent: -100,
      duration: .6,
      ease: "expo.out"
    })
  }
  const hoverOut = () => {
    gsap.to(".arrow-right", {
      x: "0rem",
      duration: .6,
      ease: "expo.out"
    })
    gsap.to(".cta-text", {
      yPercent: 0,
      duration: .6,
      ease: "expo.out"
    })
  }
  return (
    <section className="sync-row min-h-screen h-[calc(100vh+6rem)] text-center mobile:h-auto w-[100%] relative overflow-hidden flex flex-col justify-evenly">
      <div className="flex flex-col gap-[.6rem] pt-[3.8rem] pb-[1.6rem] h-[calc(6rem+19vh)] justify-evenly mobile:pt-[8rem] mobile:h-auto ">
       <div onClick={handleScrollToFooter}>
        <Sticky vars={{ selector: ".waitlist-cta", callIn: hoverIn, callOut: hoverOut }}>
          <div className="text-regular30 waitlist-cta w-[10rem] relative cursor-pointer flex justify-center items-center capitalize
      mobile:pb-[1rem] mobile:text-[.9rem] mobile:mx-auto" >
            <div className={`relative flex flex-col cursor-pointer h-[1.2rem]  overflow-hidden mobile:h-[1.4rem]`} >
              <div className="cta-text">
                Join waitlist
              </div>
              <div className="cta-text">
                Join waitlist
              </div>
            b</div>


            <svg className="w-[.6rem] ml-[.2rem] arrow-right" width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 10C1.17157 10 0.5 10.6716 0.5 11.5C0.5 12.3284 1.17157 13 2 13V10ZM23.0607 12.5607C23.6464 11.9749 23.6464 11.0251 23.0607 10.4393L13.5147 0.893398C12.9289 0.307612 11.9792 0.307612 11.3934 0.893398C10.8076 1.47918 10.8076 2.42893 11.3934 3.01472L19.8787 11.5L11.3934 19.9853C10.8076 20.5711 10.8076 21.5208 11.3934 22.1066C11.9792 22.6924 12.9289 22.6924 13.5147 22.1066L23.0607 12.5607ZM2 13H22V10H2V13Z" fill="black" />
            </svg>

            <svg className="absolute top-0 left-[50%] translate-x-[-50%] w-[6.8rem] z-[-1] mobile:w-[8rem] mobile:mt-[-.5rem] mobile:hidden" width="268" height="44" viewBox="0 0 268 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M253 29C223.312 18.4353 134.148 3.64465 15 29" stroke="#CFFFBF" strokeWidth="30" strokeLinecap="round" />
            </svg>
            <svg className="absolute hidden top-0 left-[50%] translate-x-[-50%] w-[6.8rem] z-[-1] mobile:w-[9rem] mobile:mt-[-.1rem] mobile:inline-block" width="197" height="40" viewBox="0 0 197 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M182 25C161.168 17.4538 98.6037 6.88904 15 25" stroke="#CFFFBF" strokeWidth="30" strokeLinecap="round" />
            </svg>

          </div>
        </Sticky>
        </div>

        <div className="h-[3.08rem] overflow-hidden mobile:h-[2.64rem]">
          <div className="flex flex-col srow-text-contain mt-[-.05rem]">
            <p className="text-3xl leading-[1.2] mobile:text-[2.2em]">Sync Plans</p>
            <p className="text-3xl leading-[1.2] mobile:text-[2.2em]">Share Plans</p>
            <p className="text-3xl leading-[1.2] mobile:text-[2.2em]">Join Friends</p>
            <p className="text-3xl leading-[1.2] mobile:text-[2.2em]">Create Circles</p>
            <p className="text-3xl leading-[1.2] mobile:text-[2.2em]">Book Homes</p>
            <p className="text-3xl leading-[1.2] mobile:text-[2.2em]">Split Costs</p>
            <p className="text-3xl leading-[1.2] mobile:text-[2.2em]">Make Friends</p>
            <p className="text-3xl leading-[1.2] mobile:text-[2.2em]">Sync Plans</p>
          </div>
        </div>
      </div>

      <div className="h-[calc(16rem+17vw)] mobile:w-[100vw] mobile:relative mobile:h-[28rem] mobile:overflow-hidden mobile:mt-[1rem]">
        <div className="srow-container flex flex-row overflow-hidden absolute w-[48rem] min-w-[100vw] left-[50%] translate-x-[-50%] mobile:w-[68rem]">
          <div className="flex-shrink-0 flex gap-[3vw] pl-[1.6rem] sync-row-contain tablet:gap-[1.6rem]">
            {
              EvRow.map((Item, idx) => {
                return (
                  <Image src={Item.src} key={Item.key} alt="" className={`w-[7.8rem]  min-w-[16.66vw] ${idx === 2 ? "opacity-[1]" : "opacity-[.3]"} h-auto bg-black rounded-[1.1rem] border-[.08rem] border-[#F2F2F2]
                  mobile:w-[11.8rem] mobile:border-[.12rem] mobile:rounded-[1.6rem]
                  `} />
                )
              })
            }
          </div>
          <div className="flex-shrink-0 flex gap-[3vw] pl-[1.6rem] sync-row-contain  tablet:gap-[1.6rem]">
            {
              EvRow.map((Item) => {
                return (
                  <Image src={Item.src} key={Item.key} alt="" className="w-[7.8rem] min-w-[16.66vw] opacity-[.3] h-auto bg-black rounded-[1.1rem] border-[.08rem] border-[#F2F2F2] mobile:w-[11.8rem] " />
                )
              })
            }
          </div>
        </div>
      </div>

    </section>
  )
}