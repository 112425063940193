"use client"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useEffect, useState } from "react"
import useScroll from "../../hooks/useScroll"

gsap.registerPlugin(ScrollTrigger)

export const HeroAnimations =()=>{
  useScroll()

    useEffect(()=>{
//
      // gsap.config({trialWarn: false})
      gsap.registerPlugin(ScrollTrigger)

        let mm = gsap.matchMedia();
        interface conditions {
          [key: string]: boolean;
        }
        mm.add({
          isMobile: "(max-width: 500px)",
          isTablet: "(max-width: 880px)",
          isDesktop: "(min-width: 881px)",
        },(context)=>{

          let {isMobile,isDesktop,isTablet} = context.conditions as gsap.Conditions;
          const slidesIn: HTMLElement[]= gsap.utils.toArray(".slide-in-slides")
          const slides: HTMLElement[]= gsap.utils.toArray(".slide-cards")
          gsap.set(slides, {position:()=> isMobile? "relative": "absolute"})
          gsap.set(".slide-cards",{
            y:(i)=> isMobile? "0" : -(-i* 1.25 + slides.length) +"rem",
            yPercent:()=>isMobile?0: -50,
            scale:(i)=> isMobile? 1 : i==0? .89: (1-.1/i) +.05,
          })
          const inLvl=  gsap.utils.toArray<HTMLElement>(".per-number");
          const inLvls =inLvl.map(lvl=>{
             return lvl.outerText
          })
          const roomiesCols: HTMLElement[]= gsap.utils.toArray(".roomies-col")
          function perAnim(){
            var tl = gsap.timeline()
            .fromTo(".int-percentage", {
              width:"18%"
            },{
              width:(i:number)=> inLvls[i],
              duration:.9,
              stagger:.05,
              ease: "power2.inOut",
            //  width:"40%"
            });
            return tl
          }
          function matchAnim (){
            var tl = gsap.timeline()
            .fromTo(".match svg path",{
              strokeDashoffset:()=>999,
            },{
              strokeDashoffset:()=>330,
              duration:1.5,
              ease:"expo.inOut",
            });
            return tl
          }
          function mutualsTab (){
            var tl = gsap.timeline()
            .fromTo(".mutuals-container",{
              xPercent:()=>0,
            },{
              xPercent:()=>-100,
              duration:1.5,
              ease:"expo.out",
              delay:.3,
            });
            return tl
          }
          const propertiesTl = gsap.timeline({
            scrollTrigger:{
              trigger:".properties",
              // target:".featured-cards-container",
              start:()=>"top bottom-=20%",
              end:()=>"bottom bottom",
              // markers:true,
              scrub:.1,
            },
            ease: "none",
          })
          .fromTo(".properties-row",{
            xPercent:()=> -10,
          },{
            xPercent:()=> isMobile? -102 :-105,
            ease: "none",
          })
          const homeMembersTl = gsap.timeline({
            scrollTrigger:{
              trigger:".members-container",
              start:()=>"center-=10% bottom",
              end:()=>"center+=3% center",
              // markers:true,
              // pin:true,
              scrub:.1,
            },
            ease: "power2.out",
          });

          roomiesCols.forEach((col,i)=>{
            const RoomiesTl = gsap.timeline({
              scrollTrigger:{
                trigger:col,
                start:()=>"top+=50% bottom",
                end:()=>"bottom top",
                // markers:true,
                // pin:true,
                scrub: i===2 ? true : false,
                toggleActions: "play none none reverse",
                // onEnter:()=>console.log(inLvls[0])
              },
            })
            i=== 0 && RoomiesTl.add(perAnim())
            i=== 1 && RoomiesTl.add(matchAnim())
            i===2 && isTablet && RoomiesTl.add(mutualsTab())

          })

           // events
           const syncRows= gsap.utils.toArray(".sync-row-contain")
           const syncImgs: HTMLElement[] = gsap.utils.toArray(".sync-row-contain img");
           const syncLength = [3,4,5,6,7,8,9]
           const SyncRowTl = gsap.timeline({
             scrollTrigger:{
               trigger:".sync-row",

               // target:".featured-cards-container",
               start:()=>"top bottom",
               end:()=>"bottom top",
               // markers:true,
               // scrub:1,
             },
             repeat:-1,
             ease: "none",
           })
           .to(syncImgs[2],{
             autoAlpha:1,
             duration:0,
             borderColor:"#000",
           })
           syncLength.forEach((item)=>{
             SyncRowTl.to(syncRows,{
               xPercent:()=> isMobile? "-=14.25" :"-=14.25",
               duration:1,
               delay:1,
               ease: "expo.out",
             })
            SyncRowTl.to(".srow-text-contain",{
               yPercent:"-=12.5",
               duration:1,
               ease:"expo.out"
             },"<")
             .to(syncImgs,{
               autoAlpha:.3,
               duration:.5,
               borderColor:"#F2F2F2",
               ease:"power3.out"
             },"<")
             .to(syncImgs[item],{
               autoAlpha:1,
               borderColor:"#000",
               duration:.5,
               ease:"power3.out"
             },"<")

           })

          //  const CirclesGatherTl = gsap.timeline({
          //    scrollTrigger:{
          //      trigger:".cg-section",
          //      // target:".featured-cards-container",
          //      start:()=>"top+=5% top",
          //      end:()=>"bottom-=10% bottom",
          //     //  markers:true,
          //      scrub:true,
          //    },
          //    ease: "none",
          //  })
          //  .fromTo(".cg-container .circ",{
          //   //  yPercent:(i)=> isMobile? 200 :i==0 ?150 :i ==1? 150: i==2? 20: i==3? 0: 200,
          //    y:(i)=> i===0 ?"8rem" :i ===1? "11rem": i===2? "16rem": i===3? "11rem": "13rem",

          //  },{
          //    y:(i)=> i==0 ?"-1.5rem" :i ==1? "7rem" : i===2?"-3rem": i===3?"17rem":"10rem",
          //  },"<")

           const ThreeSlidesTl = gsap.timeline({
             scrollTrigger:{
               trigger:".three-slides",
               // target:".featured-cards-container",
               start:()=>"top bottom",
               end:()=>"center center+=3%",
               // markers:true,
               scrub:true,
             },
             ease: "none",
           })
           .fromTo(".ts-slides",{
             x:()=> isMobile? 0 :0,

           },{
             x:(i)=> isTablet && i===0?"-7rem": isTablet && i===1? "7rem" :i===0? "-11.2rem":"11.2rem",
             duration:4,
             ease: "none",
           })

           const pinScreens: HTMLElement[]= gsap.utils.toArray(".pin-image")
           const pinTexts: HTMLElement[]= gsap.utils.toArray(".pin-text")
           pinScreens.forEach((pin,i)=>{
             const pinTl = gsap.timeline({
               scrollTrigger:{
                 trigger:pin,
                 start:()=>"top bottom",
                 end:()=> isMobile?"top center" :i !== 2? "bottom center+=20%": "center center",
                //  markers:true,
                 // pin:true,
                 scrub:.1,
               },
              //  ease: "power2.out",
              ease: "none",

             });
             const mobilePinTexts= pinScreens[i].querySelectorAll(".pin-mobile-text p")
             if(isMobile){pinTl.fromTo(mobilePinTexts,{
              x:(i)=> i===0? "-22rem" : "22rem",
             },{
              x:(i)=> i===0? "0rem" : "0rem",
             })
            } else {
             const pinP= pinTexts[i].querySelectorAll("p")
             if(i!==2){
             pinTl.fromTo(pinP,{
               x:(i)=> i===0? "-22rem" : "22rem",
             },{
               x:(i)=> i===0? "0rem" : "0rem",
               ease:"none",
               duration:3.3,
             })
             .to(pinP,{
               x:(i)=> i===0? "10rem" : "-10rem",
               duration:1,
               ease:"none",
             })
             .to(pinP,{
               autoAlpha:0,
               duration:.5,
             },"<+.3")
           }
           else {
             pinTl.fromTo(pinP,{
               x:(i)=> i===0? "-22rem" : "22rem",
             },{
               x:(i)=> i===0? "0rem" : "0rem",
               ease:"none",
               // duration:3.3,
             })

           }
           }})
           const ScsImages:HTMLElement[]= gsap.utils.toArray(".scs-container img")
           ScsImages.forEach((simage,i)=>{
            const ScsImagesTL= gsap.timeline({
              scrollTrigger:{
                trigger:simage,
                // target:".featured-cards-container",
                start:()=>"top bottom",
                end:()=>"bottom top",
                // markers:true,
                scrub:true,
              }
            }) 
            .fromTo(simage,{
              yPercent:()=> i>= 2?-5:20
            },{
              yPercent:()=> i>= 2?10:3
            })
           })
           const ScsImagesTL= gsap.timeline({

           }) 
         
           const CiclesRotateTl = gsap.timeline({
             scrollTrigger:{
               trigger:".circles-container",
               // target:".featured-cards-container",
               start:()=>"top bottom",
               end:()=>"center center+=3%",
               // markers:true,
               scrub:true,
             },
             ease: "none",
           })
           .fromTo(".circles-container",{
             scale:()=> isMobile? 2 :2,
             rotate:()=> -80,

           },{
             scale:(i)=> isMobile? 1 :1,
             ease: "power2.in",
             rotate: 0,
           })
           .fromTo(".circles-container div",{
             rotate:()=> 80
           },{
             rotate:()=> 0,
             ease: "power2.in",
           },"<")

           const SlidesParts: HTMLElement[]= gsap.utils.toArray(".slides-part")
           const sLeftParts: HTMLElement[]= gsap.utils.toArray(".slides-Image.left")
           const srightParts: HTMLElement[]= gsap.utils.toArray(".slides-Image.right")
           const sMobileParts: HTMLElement[]= gsap.utils.toArray(".mobile-pinning-slides")
           gsap.set([sLeftParts,srightParts],{
             yPercent:-50,
           })
           SlidesParts.forEach((slide,i)=>{
             console.log(sLeftParts[2])
             const SlidesPartsTl = gsap.timeline({
               scrollTrigger:{
                 trigger:slide,
                 start:()=>"top bottom",
                 end:()=> isMobile? "bottom center":"center center-=3%",
                 // markers:true,
                 // pin:true,
                 scrub:.1,
               },
               ease: "power2.inOut",
             });
             SlidesPartsTl.fromTo(sLeftParts[i],{
               x:"-20rem",
               rotate:()=> i===1? -20:-40 ,
             },{
               x:"0",
               rotate:()=> i===1? 10:-10 ,
             })
             SlidesPartsTl.fromTo(srightParts[i],{
               x:"20rem",
               rotate:()=> i===1? 20:40 ,
             },{
               x:"0",
               rotate:()=> i===1? -10:10 ,

             },"<")
             if(isMobile){
               const mbSlideImgs= sMobileParts[i].querySelectorAll("img")
              SlidesPartsTl.fromTo(mbSlideImgs,{
                x:(i)=>i===0? "-20rem" : "20rem",
                rotate:(i)=> i===0? "-30":"30"
               },{
                 x:"0rem",
                 rotate:0,
               })
             }

           })

           const ScreensShowcaseTl = gsap.timeline({
             scrollTrigger:{
               trigger:".screens-showcase",
               // target:".featured-cards-container",
               start:()=>"top bottom",
               end:()=>"bottom top",
               // markers:true,
               scrub:true,
             },
             ease: "none",
           })
           .fromTo(".screens-showcase",{
             x:()=> isMobile? "-120rem" :"-30rem",
           },{
             x:(i)=> isMobile? "25rem" :"10rem",
             // duration:4,
             ease: "none",
           })



          if(isMobile){
            gsap.set(".slide-cards",{autoAlpha:1, display:"block"})
            homeMembersTl.fromTo(".members-container .sides",{
              x:(i)=> i===0? "-2.8rem" : "2.8rem",
              scale:()=>.86,
            },{
              x:(i)=> i===0? "2rem" : "-2rem",
              scale:()=> 1,
            });

          }
          if(isTablet){
            gsap.set(".slide-in-slides",{yPercent:0})
            slidesIn.forEach((slideIn,i) =>{
              const slideInTl = gsap.timeline({
                scrollTrigger:{
                  trigger:slideIn,
                  start:()=>"top-=10% bottom",
                  end:()=>"center+=3% center",
                  // markers:true,
                  // pin:true,
                  scrub:.1,
                },
                ease: "power2.out",
              });
              slideInTl.fromTo(slideIn,{
                xPercent:()=> i===0? -140 : 140,
                rotate:()=>i===0? -20 : 20,
                yPercent:0,
              },{
                xPercent:()=> i===0? 0 : 0,
                rotate:()=>i===0? -5 : 5,
              });
            })
            const TestimonialContainer: HTMLElement[]= gsap.utils.toArray(".moblile-tes-contain")
            TestimonialContainer.forEach((contentDiv:HTMLElement, index:number )=>{
              const ActiveColor: string | undefined = TestimonialContainer[index]?.dataset.color;

              const TesDisplay = gsap.timeline({
                scrollTrigger:{
                  trigger:contentDiv,
                  start:()=>"top bottom",
                  end:()=>"top+=20% center-=10%",
                  // markers:true,
                  // onLeave:()=> LeaveAnimation(index),
                  // onEnterBack:()=>gsap.to(imgsMask[index],{opacity:1, duration:0,display:"flex"}),
                  scrub:true,
                },
                // onComplete:()=>{ 0 !== index? gsap.to(imgsMask[index],{display:"none"}) : null},
                ease: "none",
              })
              .to(".test-bg-mobile",{
                backgroundImage:ActiveColor,
                // duration:.5,
              })
             })
             const CirclesGatherTl = gsap.timeline({
              scrollTrigger:{
                trigger:".cg-section",
                // target:".featured-cards-container",
                start:()=>"top+=5% top",
                end:()=>"bottom-=10% bottom",
               //  markers:true,
                scrub:true,
              },
              ease: "none",
            })
            .fromTo(".cg-container .circ",{
             //  yPercent:(i)=> isMobile? 200 :i==0 ?150 :i ==1? 150: i==2? 20: i==3? 0: 200,
              y:(i)=> isMobile?"0rem": i===0 ?"9rem" :i ===1? "12rem": i===2? "17rem": i===3? "12rem": "14rem",
 
            },{
              y:(i)=> isMobile?"0rem": i==0 ?"-16.5rem" :i ==1? "4rem" : i===2?"-24rem": i===3?"14rem":"6rem",
            },"<")

          }
          if (!isMobile){
            const featuresTl = gsap.timeline({
              scrollTrigger:{
                trigger:".features",
                // target:".featured-cards-container",
                // start:()=>"top bottom",
                // end:()=>"bottom center",
                start:()=>"top top",
                end:()=>"bottom bottom",
                // markers:true,
                // pin: true,
                // pinType: 'fixed',
                // pinnedContainer:".features",
                // anticipatePin: 1,
                scrub:.1,
              },
              ease: "none",
            });
            featuresTl.to(slides, {
              y: ()=> "+=3rem",
              scale: ()=>"+=.1",
            })
            const slideInTl = gsap.timeline({
              scrollTrigger:{
                trigger:".slide-in-slides",
                start:()=>"top-=10% bottom",
                end:()=>"center+=3% center",
                // markers:true,
                // pin:true,
                scrub:.1,
              },
              ease: "power2.out",
            });
            slideInTl.fromTo(".slide-in-slides",{
              xPercent:(i)=> i===0? -140 : 140,
              rotate:(i)=>i===0? -20 : 20,
              yPercent: isTablet? 0: -50,
            },{
              xPercent:(i)=> i===0? 0 : 0,
              rotate:(i)=>i===0? -5 : 5,
            });
            homeMembersTl.fromTo(".members-container .sides",{
              x:(i)=> i===0? "-2.8rem" : "2.8rem",
              scale:()=>.86,
            },{
              x:(i)=> i===0? "1rem" : "-1rem",
              scale:()=> 1,
            });
          }
          if(isDesktop){
            const TestimonialImages: HTMLElement[]= gsap.utils.toArray(".images-container img")

          gsap.set(TestimonialImages,{
            // y:(i)=> (-(-i* 1.25 + slides.length))-5 +"rem",
            y:(i)=> -i/10 * 6.5 +1 +"rem",
            yPercent:()=> -50,
            zIndex:(i)=> TestimonialImages.length - i,
            scale:(i)=>10/(i+5)*.3 +.3,
            opacity:(i)=> (-i* .7 + 2.6)*.82,

          })

          const TesTl = gsap.timeline({
            scrollTrigger:{
              trigger:".testimonials",
              start:()=>"top top",
              end:()=>"bottom bottom",
              scrub:true,
            },
            ease: "none",
          });
          TesTl.to(TestimonialImages, {
            scale: (i)=> "+=.25",
            // y:(i)=>"+=" + ((i/10) * 1.1) + 1.6 +"rem",
            y:(i)=>"+=" + 2 +"rem",

            opacity: ()=> "+=2.5",
            // onComplete:()=>{console.log(((0/10) * 1.1) + 1.6,((2/10) * 1.1) + 1.6 ,((5/10) * 1.1) + 1.6,((7/10) * 1.1) + 1.6)}
          });

         const imgsMask:HTMLElement[]=gsap.utils.toArray(".test-img-mask")
          const tesContent:HTMLElement[]=gsap.utils.toArray(".tes-scroller")
          tesContent.forEach((contentDiv:HTMLElement, index:number )=>{
            const ActiveColor: string | undefined = tesContent[index]?.dataset.color;
            const LeaveAnimation=(i:number)=>{
              if(index !== 3){ gsap.to(imgsMask[i],{opacity:0, duration:0})}

            }
            const TesDisplay = gsap.timeline({
              scrollTrigger:{
                trigger:contentDiv,
                start:()=>"top bottom",
                end:()=>"bottom center-=10%",
                // markers:true,
                onLeave:()=> LeaveAnimation(index),
                onEnterBack:()=>gsap.to(imgsMask[index],{opacity:1, duration:0,display:"flex"}),
                scrub:true,
              },
              // onComplete:()=>{ 0 !== index? gsap.to(imgsMask[index],{display:"none"}) : null},
              ease: "none",
            })
            if(ActiveColor?.startsWith("#")){
              TesDisplay.to(".frame-container",{
                background:ActiveColor,
                // backgroundImage:ActiveColor,
                duration:.5,
              })
            }else {
              TesDisplay.to(".frame-container",{
                // background:ActiveColor,
                backgroundImage:ActiveColor,
                duration:.5,
              })
            }
           })
           const scsTL= gsap.timeline({
            scrollTrigger:{
              trigger:".scs-container",
              // target:".featured-cards-container",
              start:()=>"top bottom",
              end:()=>"bottom top+=3%",
              // markers:true,
              scrub:true,
            },
            ease: "none",
           })
           .fromTo(".sc-col",{
              yPercent:(i)=> i===0 ?"-10":"10"
           },{
            yPercent:(i)=> i===0 ?10:-10
           })

           const CirclesGatherTl = gsap.timeline({
            scrollTrigger:{
              trigger:".cg-section",
              // target:".featured-cards-container",
              start:()=>"top+=5% top",
              end:()=>"bottom-=10% bottom",
             //  markers:true,
              scrub:true,
            },
            ease: "none",
          })
          .fromTo(".cg-container .circ",{
           //  yPercent:(i)=> isMobile? 200 :i==0 ?150 :i ==1? 150: i==2? 20: i==3? 0: 200,
            y:(i)=> i===0 ?"8rem" :i ===1? "11rem": i===2? "16rem": i===3? "11rem": "13rem",

          },{
            y:(i)=> i==0 ?"-1.5rem" :i ==1? "7rem" : i===2?"-3rem": i===3?"17rem":"10rem",
          },"<")


          }
          const selectedRoom = gsap.utils.toArray(".room-border")
          gsap.set("#click ,#click2, #click3",{opacity:0})
          const click= gsap.timeline({
          })
          .to(".pointer-svg",{
            y:()=> "-7rem",
            x:()=> isMobile? "5em" : "7em",
            duration:.6,
            ease:"expo.out",
          })
          .to(".pointer-svg",{
            scale:.9,
            duration:.1,
            ease:"power3.out",
          })
          .to("#click ,#click2, #click3",{
            opacity:1,
            duration:.1,
          })
          .to("#click ,#click2, #click3",{
            opacity:0,
            duration:.1,
          })
          .to(".pointer-svg",{
            scale:1,
            duration:.1,
            ease:"power3.out",
          },"<")
          .fromTo(".click-bg",{
            width:"0%",
            height:"0%",
          },{
            width:"140%",
            height:"140%",
            duration:.5,
            ease:"expo.out",
          })
          .fromTo(".room-border",{
            borderColor:"#000"
          },{
            borderColor:"#FFF",
            duration:.5,
            ease:"expo.out",
          },"<")
          click.pause();
          const SelectRoomTl= gsap.timeline({
            scrollTrigger:{
              trigger:".select-room",
              start:()=>"top bottom",
              end:()=>"center-=20% center",
              scrub:.1,
            },
            onComplete:()=> {click.play()},
            // onComplete:()=> {console.log(selectedRoom[0].getBoundingClientRect().left)},

            onReverseComplete:()=>{click.pause(0)},
            // onRepeat:()=> console.log("repeating"),
          })
          .fromTo(".select-room .from-left",{
            x:(i)=> -5 *(i+1) +"rem",
          },{
            x:()=>0,
          })
          .fromTo(".select-room .from-right",{
            x:(i)=> 5 *(i+1) +"rem",
          },{
            x:()=>0,
          },"<")

        },".page-wrapper")
        // gsap.set('.bg-container', {width:"86%"})
        let ctx = gsap.context(() => {

          const tl = gsap.timeline({
            scrollTrigger:{
              trigger:".scaling-bg",
              start:()=>"top bottom-=5%",
              end:()=>"center+=10% center",
              // markers:true,
              // pin:true,
              scrub:.1,
            },
            ease: "none",
          });
          tl.fromTo(".bg-container",{
            width:"75%",
          },{
            width:"107%",
          })
          tl.fromTo(".left-map",{
            xPercent:-80,
            // scale:1.4,
          },{
            xPercent:0,
            // scale:1,
          },"<")
          .fromTo(".right-map",{
            xPercent:80,
            // scale:1.4,
          },{
            xPercent:0,
            // scale:1,
          },"<")
          .fromTo(".left-pin",{
            xPercent:-120,
            // scale:1.4,
          },{
            xPercent:0,
            // scale:1,
          },"<")
          .fromTo(".right-pin",{
            right:"-20rem",
            // scale:1.4,
          },{
            right:"0rem",
            // scale:1,
          },"<")
          tl.to(".scaling-bg",{
            // borderRadius:"0rem",
            scaleY:()=> 1.1,
          },"<");



          // gsap.set(".slide-cards .card-opacity",{
          //   opacity:(i)=> i === slides.length -1? 0: (-i+1* .7 + 2.6)*.22,
          // })




          // const inTubes= gsap.utils.toArray(".interest-tube")




          }, ".page-wrapper");


          return () => {
            // ScrollTrigger.getAll.kill();
            ctx.revert();
            mm.revert();
            }

    },[])

    return null
}