import Image from "next/image";
import React,{MouseEventHandler} from "react";

interface PropertyCardProps {
  backgroundImage: string;
  spotsLeft: number;
  avatars: string[];
  location: string;
  dateRange: string;
  pricePerNight: number;
  hoverIn:MouseEventHandler<HTMLDivElement>;
  hoverOut:MouseEventHandler<HTMLDivElement>;

}

const PropertyCard: React.FC<PropertyCardProps> = ({
  backgroundImage,
  spotsLeft,
  avatars,
  location,
  dateRange,
  pricePerNight,
  hoverIn,
  hoverOut
}) => {
  return (
    <div
      className="w-[14.2rem] shrink-0 bg-[length:100%] bg-center bg-no-repeat relative rounded-[30px] mobile:w-[17.2rem]"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
      onMouseEnter={hoverIn}
      onMouseLeave={hoverOut}
    >
      {/* Top-left badge */}
      <div className="absolute top-0 left-0 m-[0.57rem] flex items-center">
        <div className="flex py-[.23rem] px-[.5rem] rounded-full bg-[#00000078] backdrop-blur">
          <span className="text-[11px] text-white-100 font-medium text-white lg:text-[0.45rem]">
          {spotsLeft} Spot left
        </span>
        </div>
      </div>

      {/* Top-right badge */}
      <div className="absolute top-0 right-0 m-[0.57rem] ">
        <span className="text-xs font-semibold px-2 lg:px-0 text-white rounded-full backdrop-blur flex items-center lg:text-[0.45rem]">
          <div className="relative w-[28px] h-[28px] lg:w-[1.2rem] lg:h-[1.2rem] ">
            <Image
              src="/icons/heartIcon.svg"
              alt="heart icon"
              className=" "
              fill
            />
          </div>
        </span>
      </div>
      <div className="absolute bottom-[7px] left-[7px] right-[7px] lg:bottom-[0.3rem] lg:left-[0.3rem] lg:right-[0.3rem] p-4 lg:py-[0.6rem] lg:py-0.25 lg:px-3 rounded-[25px] lg:rounded-8.75 flex justify-between lg:min-h-oneRow lg:items-center">
        <div
          className="absolute inset-0 rounded-[.9rem] lg:rounded-[.9rem] lg:min-h-oneRow mobile:rounded-[1.4rem]"
          style={{
            background: "rgba(0, 0, 0, 0.55)",
            backdropFilter: "blur(2.878981113433838px)",
          }}
        />
        <div className="flex flex-col gap-[.4rem] justify-between z-20 ">
          {/* tracking-wider */}
          <h4 className="text-white-100 z-20 lg:text-sm25 text-[.6rem] mobile:text-[.9rem]">
            {location}
          </h4>
          <div className="flex gap-[0.6rem] justify-start">
            <div className="flex lg:items-center ">
              {avatars.map((avatar, index) => (
                <div key={index} className="relative pb-[.1rem]">
                  <div className="relative mr-[-0.3rem] w-[.6rem] h-[.6rem]  lg:h-[.55rem] lg:w-[.55rem]">
                    <Image
                      src={avatar}
                      alt={`Avatar ${index + 1}`}
                      fill
                      className="rounded-full object-cover"
                    />
                  </div>
                </div>
              ))}
            </div>
            <p className="text-white-100 text-xs z-20 lg:text-sm20 text-[.48rem] mobile:text-[.71rem]">
              Join {avatars.length} roomies
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-[.4rem] text-right justify-between z-20 ">
          {/* tracking-wider */}
          <h4 className="text-white-100 text-xs z-20  lg:text-sm20 text-[.48rem] mobile:text-[.71rem]">
            {dateRange}
          </h4>
          <div className="flex gap-2">
            <p className="text-white-100 text-[15px] z-20 lg:text-sm25 text-[.6rem] mobile:text-[.9rem]">
              ${pricePerNight} night
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;
