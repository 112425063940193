"use client";
import React from "react";
import gsap from "gsap"
import PropertyCard from "./PropertyCard";

const avatars = [
  "/assets/users/person1.jpg",
  "/assets/users/person3.jpg",
  "/assets/users/member2.jpg",
  "/assets/users/new-m4.jpg",
];
const avatars2 = [
  "/assets/users/person3.jpg",
  "/assets/users/new-m5.jpg",
  "/assets/users/member1.jpg",
  "/assets/users/new-m1.jpg",
  "/assets/users/new-m7.jpg",
];
const avatars3 = [
  "/assets/users/new-m6.jpg",
  "/assets/users/new-m3.jpg",
  "/assets/users/member2.jpg",
  "/assets/users/new-m5.jpg",
];
const avatars4 = [
  "/assets/users/new-m5.jpg",
  "/assets/users/member2.jpg",
  "/assets/users/new-m6.jpg",
];

const properties = [
  {
    backgroundImage: "/assets/events/props1.jpg",
    spotsLeft: 1,
    avatars: avatars,
    location: "Mykonos, Greece",
    dateRange: "Aug 22-27",
    pricePerNight: 129,
  },
  {
    backgroundImage: "/assets/events/props4.jpg",
    spotsLeft: 1,
    avatars: avatars2,
    location: "Fuerteventura, Spain",
    dateRange: "Jan 20-26",
    pricePerNight: 149,
  },
  {
    backgroundImage: "/assets/events/props2.jpg",
    spotsLeft: 1,
    avatars: avatars3,
    location: "Saint Tropez, France",
    dateRange: "Apr 10-17",
    pricePerNight: 230,
  },
  // {
  //   backgroundImage: "/assets/events/props3.jpg",
  //   spotsLeft: 1,
  //   avatars: avatars4,
  //   location: "Ericeira, Portugal",
  //   dateRange: "Mar 05-11",
  //   pricePerNight: 110,
  // },
];
const PropertyCardsCarousel = () => {
  function handleScrollToFooter() {
    // Using setTimeout to put the scroll action at the end of the event queue
    setTimeout(() => {
      const footer = document.getElementById("footer");
      if (footer) {
        footer.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  }
  const HandleHoverIn = (e: React.MouseEvent<HTMLDivElement>)=>{
    const width = window.innerWidth;
    if(width>500){
      gsap.to(e.target,
        {
        backgroundSize:()=>"110%",
        duration:.55,
        ease:"power3.out"
        // scale:1.1,
      })
    }
  }
  const HandleHoverOut = (e: React.MouseEvent<HTMLDivElement>)=>{
    gsap.to(e.target,
      {
      backgroundSize:()=>"100%",
      duration:.55,
      ease:"power3.out"
      // scale:1.1,
    })

  }
  return (
    <section className="properties flex relative flex-col bg-white-100 h-[350vh] w-full items-start justify-start items-center pt-[1rem] pb-[3rem] mobile:h-[200vh]">
      <div className="px-mobile w-[100vw] overflow-hidden lg:px-0 w-full h-[18rem] sticky top-[calc((100vh-18rem)/2)] mobile:h-auto mobile:top-[calc((100vh-21rem)/2)]">
        <p className="text-[1.28rem] pl-[8.33vw] tablet:pl-0">Gather Globally in +50,000 Properties</p>
        {/* <Navbar /> */}
        {/* Mobile carousel */}
        <div className="hidden flex mt-2 overflow-x-auto snap-x snap-mandatory scrollbar-hide">
          {properties.map((property, index) => (
            <div
              key={index}
              className="flex-shrink-0 snap-center mr-2"
              style={{
                minWidth: "calc(100vw * 15.8 / 18.75)",
              }} //change
            >
              <PropertyCard {...property} hoverIn={HandleHoverIn} hoverOut={HandleHoverOut} />
            </div>
          ))}
          <div className="w-[14.2rem] shrink-0 bg-black rounded-[1rem]"></div>
        </div>
        {/* End mobile carousel */}

        {/* Lg view */}
        <div className="hidden mobile:block relative w-full h-[22.2rem]"></div>
        <div className="properties-row cursor-pointer w-auto absolute left-[100%] flex gap-[2rem] h-[14rem] mt-[1.45rem] mobile:h-[17.2rem] mobile:top-[4rem]">          {properties.map((property, index) => {
            return <PropertyCard key={index} {...property} hoverIn={HandleHoverIn} hoverOut={HandleHoverOut}/>;
          })}
          <div
            onClick={handleScrollToFooter}
            className="w-[14.2rem] relative shrink-0 border-[#E6E6E6] border-[.06rem] bg-white-100 shadow-xxsm rounded-[1.2rem] px-[1.5rem] py-[1.65rem] mobile:w-[17.2rem]"
          >
            <p className="text-3xl text-black">
              Early
              <span className="block">Access</span>
            </p>
            <div className="bg-black rounded-full absolute w-[3rem] h-[3rem] flex right-[.7rem] bottom-[.7rem] items-center justify-center">
              <svg
                className="w-[1.05rem] h-auto"
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="43"
                viewBox="0 0 42 43"
                fill="none"
              >
                <path
                  d="M0.933775 37.0627C-0.20629 38.2027 -0.20629 40.0512 0.933775 41.1912C2.07384 42.3313 3.92225 42.3313 5.06232 41.1912L0.933775 37.0627ZM41.2393 3.80498C41.2393 2.19268 39.9323 0.885655 38.32 0.885655H12.0461C10.4338 0.885655 9.12682 2.19268 9.12682 3.80498C9.12682 5.41727 10.4338 6.7243 12.0461 6.7243L35.4007 6.7243L35.4007 30.0789C35.4007 31.6912 36.7077 32.9982 38.32 32.9982C39.9323 32.9982 41.2393 31.6912 41.2393 30.0789V3.80498ZM5.06232 41.1912L40.3843 5.86925L36.2558 1.7407L0.933775 37.0627L5.06232 41.1912Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PropertyCardsCarousel;
