"use client"
import Lenis from '@studio-freight/lenis'
import{useContext} from "react"
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useLayoutEffect } from "react"
import gsap from "gsap"
import  {ThemeContext}  from "../providers/index";
gsap.registerPlugin(ScrollTrigger)

export default function useScroll(){
  
  const { setLenisScroll } = useContext(ThemeContext);
    useLayoutEffect (()=>{
        const lenis = new Lenis({
          duration: 1.2 as number,
          easing: (t:number) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
          smoothTouch: false as boolean,
        })
    
        function raf(time:number) {
          lenis.raf(time)
          ScrollTrigger.update()
          requestAnimationFrame(raf)
        }
    
      requestAnimationFrame(raf)
      // console.log(typeof lenis)
      setLenisScroll({lenis})

      return () => {

        if( lenis && lenis.destroy ) {
  
          document.body.classList.remove('smooth')
          lenis.destroy()
          
        }
      }
      },[])
}
